<template>
  <div>
    <div class="form-content">
      <h1 class="">Password Reset</h1>
      <p class="signup-link">
        You're almost there. Set a new password for your account!
      </p>
      <form class="text-left" @submit.prevent="resetPassword()">
        <div class="form">
          <input type="hidden" name="token" v-model="token" />

          <div id="password-field" class="field-wrapper input mb-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-lock"
            >
              <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
              <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
            </svg>
            <input
              id="password"
              name="password"
              type="password"
              v-model="password"
              class="form-control"
              placeholder="New Password"
            />
          </div>

          <div id="confirm_password-field" class="field-wrapper input mb-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-lock"
            >
              <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
              <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
            </svg>
            <input
              id="confirm_password"
              name="password"
              type="password"
              v-model="confirm_password"
              class="form-control"
              placeholder="Confirm New Password"
            />
          </div>

          <div class="d-sm-flex justify-content-between">
            <div class="field-wrapper">
              <button
                type="submit"
                class="btn btn-primary"
                value=""
                :disabled="loading"
              >
                <b-spinner small v-if="loading"></b-spinner>
                <span v-else>Continue</span>
              </button>
            </div>
          </div>
        </div>
      </form>

      <div class="text-center">
        <p class="mt-5 pt-5 small">© 2021. Gerocare Solutions LTD.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from "../../utils/http";
import { endpoints } from "../../utils/endpoints";

export default {
  name: "SetPassword",

  data() {
    return {
      token: "",
      password: "",
      confirm_password: "",
      loading: false,
    };
  },

  mounted() {
    this.grabTokenFromUrl();
  },

  methods: {
    resetPassword() {
      if (this.password.length < 8 || this.confirm_password.length < 8) {
        this.$toast.error("Password should be more than 8 characters!");
      } else if (this.password.length != this.confirm_password.length) {
        this.$toast.error("Passwords do not match!");
      } else {
        this.loading = true;
        http
          .post(endpoints.SET_NEW_PASSWORD, {
            token: this.token,
            password: this.password,
          })
          .then((response) => {
            this.loading = false;
            this.$toast.success(response);
            this.$router.push("/setpasswordsuccess");
          })
          .catch((error) => {
            this.loading = false;
            this.$toast.error(
              error.response?.data?.message ||
                error.response?.message ||
                error.response?.data
            );
          });
      }
    },

    grabTokenFromUrl() {
      if (
        this.$route.params.id == null ||
        typeof this.$route.params.id == "undefined"
      ) {
        this.$router.push("/login");
      } else {
        this.token = this.$route.params.id;
      }
    },
  },
};
</script>
